import { collection, doc, onSnapshot, query, setDoc, where } from 'firebase/firestore'
import { useAtom } from 'jotai'
import { useEffect } from 'react'

import { currentConversationIdAtom, loadingAtom } from '@/global-state/jotai-atoms'
import { createUUID } from '@/utils/common-utils'
import { useQueryClient } from '@tanstack/react-query'
import { SUBSCRIBE_PATH, SubscribeType, subscribeModelConverter } from '../converter/SubscribeModelConverter'
import { firestoreDB } from '../firebase-utils'

export const useSubscribe = () => {
  const queryClient = useQueryClient()
  const [, setLoading] = useAtom(loadingAtom)
  const [currentConversationId] = useAtom(currentConversationIdAtom)

  useEffect(() => {
    if (!currentConversationId) {
      return
    }
    const unsubscribe = onSnapshot(
      query(collection(firestoreDB, SUBSCRIBE_PATH), where('conversationId', '==', currentConversationId)),
      async (querySnapshot) => {
        const snapshot = querySnapshot.docs.map((doc) => doc.data())
        if (snapshot.length > 0) {
          await queryClient.refetchQueries({
            queryKey: ['/app/users'],
          })
          await queryClient.refetchQueries({
            queryKey: ['/app/conversations/me/list'],
          })
          await queryClient.refetchQueries({
            queryKey: ['/app/conversations', currentConversationId],
          })
        }
      },
      (error) => {
        console.error(error)
      },
    )
    return () => unsubscribe()
  }, [currentConversationId])

  const addSubscribe = async () => {
    if (!currentConversationId) {
      return
    }
    setLoading(true)
    try {
      const id = createUUID()
      const subscribe: SubscribeType = {
        id: id,
        conversationId: currentConversationId,
        createTime: Date.now(),
        updateTime: Date.now(),
      }
      const docRef = doc(firestoreDB, `/${SUBSCRIBE_PATH}`, subscribe.conversationId).withConverter(
        subscribeModelConverter,
      )
      await setDoc(docRef, subscribe)
    } finally {
      setLoading(false)
    }
  }

  return {
    addSubscribe,
  }
}
