import { css } from '@emotion/react'
import { appColor } from './app-colors'
import { notojp } from './app-typography'

export const appPrimaryButtonStyled = css`
    height: 53px;
    background: linear-gradient(0.25turn, ${appColor.Service.purple} 30%, ${appColor.Service.pink});
    color: ${appColor.Text.white};
    font-family: ${notojp.style.fontFamily};
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    border-radius: 32px;
    width: 100%;
    &:hover {
        opacity: 0.9;
        background: linear-gradient(0.25turn, ${appColor.Service.purple} 30%, ${appColor.Service.pink});
    }
    &:active {
        background: linear-gradient(0.25turn, ${appColor.Service.purple} 30%, ${appColor.Service.pink});
    }
    &:focus {
        background: linear-gradient(0.25turn, ${appColor.Service.purple} 30%, ${appColor.Service.pink});
    }
    &:disabled {
        background: ${appColor.Service.grayHard};
        color: ${appColor.Text.white};
        opacity: 0.5;
    }
`

export const appSecondaryButtonStyled = css`
    font-family: ${notojp.style.fontFamily};
`

export const appPrimaryTextFieldStyled = css`
  font-family: ${notojp.style.fontFamily};
  width: 100%;
  & .MuiInputBase-input {
    font-family: ${notojp.style.fontFamily};
    font-size: 16px;
  }
`

export const appSecondaryTextFieldStyled = css`
  font-family: ${notojp.style.fontFamily};
  width: 100%;
  & .MuiInputBase-input {
    font-family: ${notojp.style.fontFamily};
    font-weight: 400;
    font-size: 16px;
    padding: 12px 12px;
  }
`

export const appPrimaryDatePickerStyled = css`
  font-family: ${notojp.style.fontFamily};
  width: 100%;
  & .MuiInputBase-input {
    font-family: ${notojp.style.fontFamily};
    font-size: 16px;
  }
`

export const appPrimaryTextAreaStyled = css`
  font-family: ${notojp.style.fontFamily};
  font-size: 16px;
  font-weight: 500;
  color: ${appColor.Text.primary};
`

export const appSecondaryTextAreaStyled = css`
  font-family: ${notojp.style.fontFamily};
  font-size: 16px;
  font-weight: 400;
  color: ${appColor.Text.primary};
  & .MuiInputBase-root {
    padding: 12px 12px;
  }
`

export const appSecondaryTextAreaOutlinedStyled = css`
  font-family: ${notojp.style.fontFamily};
  background-color: ${appColor.Background.secondary};
  font-size: 16px;
  font-weight: 400;
  color: ${appColor.Text.primary};
  & .MuiInputBase-root {
    padding: 12px 12px;
    background-color: ${appColor.Background.secondary};
  }
`

export const appPrimaryCheckboxStyled = css`
  span {
    font-family: ${notojp.style.fontFamily};
    font-weight: 400;
    color: ${appColor.Text.primary};
  }
`
