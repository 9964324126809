import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore'

export const MESSAGE_PATH = 'chat_messages'

export type MessageType = {
  id: string
  conversationId: string
  responseUserId: string
  targetUserId: string
  isAdmin?: boolean
  message: {
    type: 'text' | 'imageUrl' | 'link' | 'alert' | 'file'
    text: string
  }
  createTime: number
  updateTime: number
  deletedAt?: number | null
}

export const messageModelConverter: FirestoreDataConverter<MessageType> = {
  toFirestore(message: MessageType): DocumentData {
    return {
      id: message.id,
      conversationId: message.conversationId,
      responseUserId: message.responseUserId,
      targetUserId: message.targetUserId,
      isAdmin: message.isAdmin,
      message: message.message,
      createTime: Math.round(message.createTime || 0),
      updateTime: Math.round(message.updateTime || 0),
      deletedAt: message.deletedAt ? Math.round(message.deletedAt) : null,
    }
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): MessageType {
    const data = snapshot.data(options)
    return {
      id: snapshot.id,
      conversationId: data.conversationId,
      responseUserId: data.responseUserId,
      targetUserId: data.targetUserId,
      isAdmin: data.isAdmin,
      message: data.message,
      createTime: Math.round(data.createTime || 0),
      updateTime: Math.round(data.updateTime || 0),
      deletedAt: data.deletedAt ? Math.round(data.deletedAt) : null,
    }
  },
}
