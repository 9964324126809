import { APIPaths } from '@/apis/api-config'
import { useAppApi } from '@/custom-hooks/use-app-api'
import { MESSAGE_PATH } from '@/firestore/converter/MessageModelConverter'
import { ROOM_PATH } from '@/firestore/converter/RoomModelConverter'
import { useSubscribe } from '@/firestore/hooks/use-subscribe'
import { currentConversationIdAtom, loadingAtom } from '@/global-state/jotai-atoms'
import { useQueryClient } from '@tanstack/react-query'
import { useAtom, useSetAtom } from 'jotai'
import { useCallback } from 'react'
import { useApiConfig } from '../use-api-config'

export const useAppConversationMutation = () => {
  const queryClient = useQueryClient()
  const { catchApiError } = useApiConfig()
  const setLoading = useSetAtom(loadingAtom)
  const [currentConversationId] = useAtom(currentConversationIdAtom)
  const { conversationApi } = useAppApi()
  const { addSubscribe } = useSubscribe()

  const appRefetchConversation = useCallback(async () => {
    try {
      await addSubscribe()
      await queryClient.refetchQueries({
        queryKey: ['/app/users'],
      })
      await queryClient.refetchQueries({
        queryKey: ['/app/conversations/me/list'],
      })
      await queryClient.refetchQueries({
        queryKey: ['/app/conversations', currentConversationId],
      })
      await queryClient.refetchQueries({
        queryKey: [ROOM_PATH],
      })
      await queryClient.refetchQueries({
        queryKey: [MESSAGE_PATH, currentConversationId],
      })
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, [currentConversationId])

  const appRequestTargetUserId = useCallback(
    async (
      target_user_id: APIPaths['/app/conversations/{target_user_id}/send']['put']['parameters']['path']['target_user_id'],
    ) => {
      try {
        setLoading(true)
        const res = await conversationApi.appPutTargetUserIdSend(target_user_id)
        await appRefetchConversation()
        return res
      } catch (error) {
        catchApiError(error)
      } finally {
        setLoading(false)
      }
    },
    [conversationApi, currentConversationId],
  )

  const appCancelConversation = useCallback(
    async (
      conversation_id: APIPaths['/app/conversations/{conversation_id}/cancel']['delete']['parameters']['path']['conversation_id'],
    ) => {
      try {
        setLoading(true)
        const res = await conversationApi.appDeleteConversationCancel(conversation_id)
        await appRefetchConversation()
        return res
      } catch (error) {
        catchApiError(error)
      } finally {
        setLoading(false)
      }
    },
    [conversationApi, currentConversationId],
  )

  const appApproveConversation = useCallback(
    async (
      conversation_id: APIPaths['/app/conversations/{conversation_id}/approve']['put']['parameters']['path']['conversation_id'],
    ) => {
      try {
        setLoading(true)
        const res = await conversationApi.appPutConversationApprove(conversation_id)
        await appRefetchConversation()
        return res
      } catch (error) {
        catchApiError(error)
      } finally {
        setLoading(false)
      }
    },
    [conversationApi, currentConversationId],
  )

  const appRejectConversation = useCallback(
    async (
      conversation_id: APIPaths['/app/conversations/{conversation_id}/reject']['delete']['parameters']['path']['conversation_id'],
    ) => {
      try {
        setLoading(true)
        const res = await conversationApi.appDeleteConversationReject(conversation_id)
        await appRefetchConversation()
        return res
      } catch (error) {
        catchApiError(error)
      } finally {
        setLoading(false)
      }
    },
    [conversationApi, currentConversationId],
  )

  const appSendScheduleConversation = useCallback(
    async (
      conversation_id: APIPaths['/app/conversations/{conversation_id}/schedule']['put']['parameters']['path']['conversation_id'],
      requestBody: APIPaths['/app/conversations/{conversation_id}/schedule']['put']['requestBody']['content']['application/json'],
    ) => {
      try {
        setLoading(true)
        const res = await conversationApi.appPutConversationSchedule(conversation_id, requestBody)
        await appRefetchConversation()
        return res
      } catch (error) {
        catchApiError(error)
      } finally {
        setLoading(false)
      }
    },
    [conversationApi, currentConversationId],
  )

  const appEditScheduleConversation = useCallback(
    async (
      conversation_id: APIPaths['/app/conversations/{conversation_id}/schedule-edit']['put']['parameters']['path']['conversation_id'],
      requestBody: APIPaths['/app/conversations/{conversation_id}/schedule-edit']['put']['requestBody']['content']['application/json'],
    ) => {
      try {
        setLoading(true)
        const res = await conversationApi.appPutConversationScheduleEdit(conversation_id, requestBody)
        await appRefetchConversation()
        return res
      } catch (error) {
        catchApiError(error)
      } finally {
        setLoading(false)
      }
    },
    [conversationApi, currentConversationId],
  )

  const appCancelScheduleConversation = useCallback(
    async (
      conversation_id: APIPaths['/app/conversations/{conversation_id}/schedule-cancel']['put']['parameters']['path']['conversation_id'],
    ) => {
      try {
        setLoading(true)
        const res = await conversationApi.appPutConversationScheduleCancel(conversation_id)
        await appRefetchConversation()
        return res
      } catch (error) {
        catchApiError(error)
      } finally {
        setLoading(false)
      }
    },
    [conversationApi, currentConversationId],
  )

  const appAcceptScheduleConversation = useCallback(
    async (
      conversation_id: APIPaths['/app/conversations/{conversation_id}/schedule-accept/{decide_schedule_id}']['put']['parameters']['path']['conversation_id'],
      decide_schedule_id: APIPaths['/app/conversations/{conversation_id}/schedule-accept/{decide_schedule_id}']['put']['parameters']['path']['decide_schedule_id'],
    ) => {
      try {
        setLoading(true)
        const res = await conversationApi.appPutConversationScheduleAccept(conversation_id, decide_schedule_id)
        await appRefetchConversation()
        return res
      } catch (error) {
        catchApiError(error)
      } finally {
        setLoading(false)
      }
    },
    [conversationApi, currentConversationId],
  )

  const appCompleteConversation = useCallback(
    async (
      conversation_id: APIPaths['/app/conversations/{conversation_id}/complete']['put']['parameters']['path']['conversation_id'],
    ) => {
      try {
        setLoading(true)
        const res = await conversationApi.appPutConversationComplete(conversation_id)
        await appRefetchConversation()
        return res
      } catch (error) {
        catchApiError(error)
      } finally {
        setLoading(false)
      }
    },
    [conversationApi, currentConversationId],
  )

  return {
    appRequestTargetUserId,
    appCancelConversation,
    appApproveConversation,
    appRejectConversation,
    appSendScheduleConversation,
    appEditScheduleConversation,
    appCancelScheduleConversation,
    appAcceptScheduleConversation,
    appCompleteConversation,
  }
}
