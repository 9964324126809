import { APISchema } from '@/apis/api-config'
import { useAppConversationMutation } from '@/custom-hooks/api-mutation/use-app-conversation-mutation'
import { useApiConfig } from '@/custom-hooks/use-api-config'
import { useAppSnackbar } from '@/custom-hooks/use-app-snackbar'
import { appColor } from '@/styles/app-colors'
import { notojp } from '@/styles/app-typography'
import { Close } from '@mui/icons-material'
import { Alert, Button, Dialog, DialogContent, Divider, Grid, IconButton, css } from '@mui/material'
import React from 'react'

type Props = {
  user: APISchema['User']
  open: boolean
  onClose: () => void
}

const RequestSendDialog: React.FC<Props> = (props) => {
  const { user, open, onClose } = props
  const { setAppSnackbar } = useAppSnackbar()
  const { refetchAuthUser } = useApiConfig()
  const { appRequestTargetUserId } = useAppConversationMutation()

  const onClickSend = async () => {
    const result = await appRequestTargetUserId(user.id)
    if (result?.id) {
      await refetchAuthUser()
      setAppSnackbar('リクエストを送信しました', { info: true })
      onClose()
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'xs'}>
      <DialogContent>
        <Grid container item xs={12} direction="row" justifyContent="space-between" alignItems="center" pb={1}>
          <Grid item css={titleStyled}>
            リクエストを送る
          </Grid>
          <Grid item>
            <IconButton color={'inherit'} size={'small'} onClick={onClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
        <Grid container item xs={12} py={2}>
          <Alert severity="warning">
            {user.profile?.name}さんにリクエストを送りますか？
            <br />
            ※リクエストを送ると、相手に通知が送られます。
          </Alert>
        </Grid>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Button type="submit" fullWidth color={'secondary'} variant="contained" size={'medium'} onClick={onClose}>
              キャンセル
            </Button>
          </Grid>
          <Grid item>
            <Button type="submit" fullWidth variant="contained" size={'medium'} onClick={onClickSend}>
              送信
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default React.memo(RequestSendDialog)

const titleStyled = css`
  font-family: ${notojp.style.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.1em;
  color: ${appColor.Text.primary};
`
