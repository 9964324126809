import { authUserAtom } from '@/global-state/jotai-atoms'
import { useQueryClient } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { useAppApi } from './use-app-api'
import { useAppSnackbar } from './use-app-snackbar'

export const useApiConfig = () => {
  const queryClient = useQueryClient()
  const { userApi } = useAppApi()
  const { setAppSnackbar } = useAppSnackbar()
  const setAuthUser = useSetAtom(authUserAtom)

  const catchApiError = (error: any) => {
    console.error(error)
    const errMsg = error?.response?.data?.detail || 'エラーが発生しました'
    setAppSnackbar(errMsg, {
      error: true,
    })
  }

  const refetchAuthUser = async () => {
    try {
      await queryClient.refetchQueries({
        queryKey: ['/app/users/list'],
      })
      await queryClient.refetchQueries({
        queryKey: ['/app/users/me'],
      })
      await queryClient.refetchQueries({
        queryKey: ['/app/conversations/me/list'],
      })
      const result = await userApi.appGetMe()
      setAuthUser(result)
    } catch (error) {
      catchApiError(error)
    }
  }

  return {
    catchApiError,
    refetchAuthUser,
  }
}
