import { APISchema } from './api-config'

export const GENDER_SELECT_MODEL: Array<{ type: APISchema['Gender']; value: string }> = [
  {
    type: 'MALE',
    value: '男性',
  },
  {
    type: 'FEMALE',
    value: '女性',
  },
  {
    type: 'OTHERS',
    value: '無回答',
  },
]

export const JOB_TYPE_SELECT_MODEL: Array<{ type: APISchema['JobCategoryType']; value: string }> = [
  {
    type: 'SALES',
    value: '営業',
  },
  {
    type: 'PLANNING_AND_MANAGEMENT',
    value: '企画・経営',
  },
  {
    type: 'ADMINISTRATION_AND_CLERICAL_WORK',
    value: '管理・事務',
  },
  {
    type: 'SALES_FOOD_AMUSEMENT',
    value: '販売・フード・アミューズメント',
  },
  {
    type: 'BEAUTY_BRIDAL_HOTEL_TRANSPORTATION',
    value: '美容・ブライダル・ホテル・交通',
  },
  {
    type: 'MEDICAL_CARE_AND_WELFARE',
    value: '医療・福祉',
  },
  {
    type: 'CHILDCARE_EDUCATION_INTERPRETATION',
    value: '保育・教育・通訳',
  },
  {
    type: 'CREATIVE',
    value: 'クリエイティブ',
  },
  {
    type: 'WEB_INTERNET_GAME',
    value: 'WEB・インターネット・ゲーム',
  },
  {
    type: 'CONSULTANT_FINANCE_REAL_ESTATE_PROFESSIONAL',
    value: 'コンサルタント・⾦融・不動産専⾨職',
  },
  {
    type: 'PUBLIC_SERVICES',
    value: '公共サービス',
  },

  {
    type: 'WEB_MARKETING',
    value: 'Webマーケティング',
  },
  {
    type: 'IT_ENGINEER',
    value: 'ITエンジニア',
  },
  {
    type: 'ELECTRICITY_ELECTRONICS_MACHINERY_SEMICONDUCTORS',
    value: '電気・電⼦・機械・半導体',
  },
  {
    type: 'CONSTRUCTION_AND_BUILDING',
    value: '建築・⼟⽊',
  },
  {
    type: 'PHARMACEUTICALS_MEALS_CHEMICALS_MATERIALS',
    value: '医薬・⾷品・化学・素材',
  },
  {
    type: 'ENGINEERING_FACILITIES_DISTRIBUTION_AGRICULTURE_FORESTRY_AND_WATER_INDUSTRY',
    value: '技能⼯・設備・配送・農林⽔産',
  },
]

export const INDUSTRY_CATEGORY_MODEL: Array<{ type: APISchema['IndustryCategoryType']; value: string }> = [
  {
    type: 'IT_AND_TELECOMMUNICATIONS',
    value: 'IT・通信',
  },
  {
    type: 'INTERNET_ADVERTISING_AND_MEDIA',
    value: 'インターネット・広告・メディア',
  },
  {
    type: 'MANUFACTURER_MACHINERY_ELECTRICAL',
    value: 'メーカー(機械・電気)',
  },
  {
    type: 'MANUFACTURER_MATERIALS_CHEMICALS_FOOD_COSMETICS_OTHERS',
    value: 'メーカー(素材・化学・食品・化粧品・その他)',
  },
  {
    type: 'TRADING_COMPANY',
    value: '商社',
  },
  {
    type: 'PHARMACEUTICALS_MEDICAL_DEVICES_LIFE_SCIENCES_MEDICAL_SERVICES',
    value: '医薬品・医療機器・ライフサイエンス・医療系サービス',
  },
  {
    type: 'FINANCING',
    value: '金融',
  },
  {
    type: 'CONSTRUCTION_PLANT_REAL_ESTATE',
    value: '建設・プラント・不動産',
  },
  {
    type: 'CONSULTING_PROFESSIONAL_FIRMS_AUDITING_FIRMS_TAX_ACCOUNTING_FIRMS_RESEARCH',
    value: 'コンサルティング・専門事務所・監査法人・税理士法人・リサーチ',
  },
  {
    type: 'HUMAN_RESOURCE_SERVICES_OUTSOURCING_CALL_CENTERS',
    value: '人材サービス・アウトソーシング・コールセンター',
  },
  {
    type: 'RETAIL',
    value: '小売',
  },
  {
    type: 'TRANSPORTATION_LOGISTICS',
    value: '運輸・物流',
  },
  {
    type: 'EATING_OUT',
    value: '外食',
  },
  {
    type: 'ENERGY_ELECTRICITY_GAS_OIL_NEW_ENERGY',
    value: 'エネルギー(電力・ガス・石油・新エネルギー)',
  },
  {
    type: 'TRAVEL_ACCOMMODATION_LEISURE',
    value: '旅行・宿泊・レジャー',
  },
  {
    type: 'SECURITY_AND_CLEANING',
    value: '警備・清掃',
  },
  {
    type: 'HAIRDRESSING_BEAUTY_ESTHETICS',
    value: '理容・美容・エステ',
  },
  {
    type: 'EDUCATION',
    value: '教育',
  },
  {
    type: 'AGRICULTURE_FORESTRY_FISHERIES_AND_MINING',
    value: '農林水産・鉱業',
  },
  {
    type: 'PUBLIC_CORPORATIONS_GOVERNMENT_OFFICES_SCHOOLS_RESEARCH_FACILITIES',
    value: '公社・官公庁・学校・研究施設',
  },
  {
    type: 'IMPORTANT_CEREMONIAL_OCCASIONS_IN_FAMILY_RELATIONSHIPS',
    value: '冠婚葬祭',
  },
  {
    type: 'OTHER',
    value: 'その他',
  },
]
