import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore'

export const SUBSCRIBE_PATH = `env/${process.env.APP_ENV}/subscribes`

export type SubscribeType = {
  id: string
  conversationId: string
  createTime: number
  updateTime: number
}

export const subscribeModelConverter: FirestoreDataConverter<SubscribeType> = {
  toFirestore(subscribe: SubscribeType): DocumentData {
    return {
      id: subscribe.id,
      conversationId: subscribe.conversationId,
      createTime: Math.round(subscribe.createTime || 0),
      updateTime: Math.round(subscribe.updateTime || 0),
    }
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): SubscribeType {
    const data = snapshot.data(options)
    return {
      id: snapshot.id,
      conversationId: data.conversationId,
      createTime: Math.round(data.createTime || 0),
      updateTime: Math.round(data.updateTime || 0),
    }
  },
}
